import React from "react";
import { Script } from "gatsby";
import "./src/styles/global.css";
import "./src/styles/tailwind.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@smastrom/react-rating/style.css";
import { store } from "./src/store";
import { Provider } from "react-redux";
import PasswordProtect from "./src/components/PasswordProtect/PasswordProtect";
import {
  getQueryPassword,
  getSessionPassword,
  setSessionPassword,
} from "./src/utils/passwordprotect";
import { TrackingProvider } from './src/tracking/TrackingContext';
import MixpanelService from './src/tracking/MixpanelService';
import trackingManager from "./src/tracking/TrackingManager";

const PASSWORD_PROTECT = process.env.GATSBY_PASSWORD ?? null;
trackingManager.registerProvider('mixpanel', MixpanelService, { token: process.env.GATSBY_MIXPANEL_TOKEN });

export const wrapRootElement = ({ element }) => {
  return (
      <TrackingProvider>
        <Provider store={store}>
          {element}
          <Script
            type="module"
            async
            src="https://ajax.googleapis.com/ajax/libs/model-viewer/3.0.1/model-viewer.min.js"
          ></Script>
          <Script
            async
            dangerouslySetInnerHTML={{
              __html: `window.addEventListener('DOMContentLoaded', function () {
                    window.FrontChat('init', {
                        chatId: '45648232f6b8f065ae6e0cd27d93c18c',
                        useDefaultLauncher: true
                    })
                });`,
            }}
          ></Script>
        </Provider>
      </TrackingProvider>
  );
};

export function shouldUpdateScroll({
  routerProps: { location },
  getSavedScrollPosition,
}) {
  const state = location?.state;
  if (state?.disableScrollUpdate) {
    return false;
  }

  const animationDuraion = 0.3;
  const scrollDelay = animationDuraion * 1000;

  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), scrollDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      scrollDelay
    );
  }

  if ("scrollRestoration" in window.history) {
    window.history.scrollRestoration = "manual";
  }

  return false;
}

export const wrapPageElement = ({ props }) => {
  const { location } = props;
  if (!PASSWORD_PROTECT) {
    return;
  }

  const queryPassword = getQueryPassword(location);
  if (queryPassword === PASSWORD_PROTECT) {
    setSessionPassword(queryPassword);
    return;
  }
  // correct password
  const sessionPassword = getSessionPassword();
  if (sessionPassword === PASSWORD_PROTECT) {
    return;
  }

  return <PasswordProtect />;
};

export const onRouteUpdate = () => {
  // Reset the datalayer between routes
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(function () {
    this.reset();
  });
};
