import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {LOGGEDIN} from "../../constants/auth";

const FAVORITES_KEY = "my_bushbuck_favorites";
const initialState = {
  favorites: [],
};

const callNetlifyFunction = async (url, body) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
  return await response.json();
};

export const getFavorites = createAsyncThunk(
    "wishlist/getFavorites",
    async (_, { getState, dispatch }) => {
      const state = getState();
      const isLoggedIn = state.auth.authState === LOGGEDIN;
      const customerId = state.auth.user?.id;
      const token = state.auth.user?.accessToken;

      if (isLoggedIn && customerId && token) {
        const { wishlist } = await callNetlifyFunction(
            '/.netlify/functions/fetchWishlist',
            { customerId, token }
        );
        dispatch(syncLocalStorageToMetafield());
        return wishlist;
      } else {
        const localFavorites = JSON.parse(localStorage.getItem(FAVORITES_KEY) || '[]');
        return localFavorites;
      }
    }
);

export const addToFavorite = createAsyncThunk(
    "wishlist/addToFavorite",
    async (product, { getState }) => {
      const state = getState();
      const isLoggedIn = state.auth.authState === LOGGEDIN;
      const customerId = state.auth.user?.id;
      const token = state.auth.user?.accessToken;

      if (isLoggedIn && customerId && token) {
        const {wishlist: prevFavorites} = await callNetlifyFunction(
            '/.netlify/functions/fetchWishlist',
            { customerId, token }
        );
        const newFavorites = [...prevFavorites, product];

        await callNetlifyFunction(
            '/.netlify/functions/updateWishlist',
            { customerId, wishlist: newFavorites, token }
        );

        return newFavorites;
      } else {
        const prevFavorites = JSON.parse(localStorage.getItem(FAVORITES_KEY) || '[]');
        const newFavorites = [...prevFavorites, product];
        localStorage.setItem(FAVORITES_KEY, JSON.stringify(newFavorites));
        return newFavorites;
      }
    }
);

export const removeFromFavorites = createAsyncThunk(
    "wishlist/removeFromFavorites",
    async (productId, { getState }) => {
      const state = getState();
      const isLoggedIn = state.auth.authState === LOGGEDIN;
      const customerId = state.auth.user?.id;
      const token = state.auth.user?.accessToken;

      if (isLoggedIn && customerId && token) {
        const { wishlist: prevFavorites = [] } = await callNetlifyFunction(
            '/.netlify/functions/fetchWishlist',
            { customerId, token }
        );
        const newFavorites = prevFavorites.filter(
            (product) => product.variant.shopifyId !== productId
        );

        await callNetlifyFunction(
            '/.netlify/functions/updateWishlist',
            { customerId, wishlist: newFavorites, token }
        );

        return newFavorites;
      } else {
        const prevFavorites = JSON.parse(localStorage.getItem(FAVORITES_KEY) || '[]');
        const newFavorites = prevFavorites.filter(
            (product) => product.variant.shopifyId !== productId
        );
        localStorage.setItem(FAVORITES_KEY, JSON.stringify(newFavorites));
        return newFavorites;
      }
    }
);

export const clearFavorites = createAsyncThunk(
    "wishlist/clearFavorites",
    async (_, { getState }) => {
      const state = getState();
      const isLoggedIn = state.auth.authState === LOGGEDIN;
      const customerId = state.auth.user?.id;
      const token = state.auth.user?.accessToken;

      if (isLoggedIn && customerId && token) {
        await callNetlifyFunction(
            '/.netlify/functions/updateWishlist',
            { customerId, wishlist: [], token }
        );
      }
      localStorage.removeItem(FAVORITES_KEY);
      return [];
    }
);

export const syncLocalStorageToMetafield = createAsyncThunk(
    "wishlist/syncLocalStorageToMetafield",
    async (_, { getState }) => {
      const state = getState();
      const isLoggedIn = state.auth.authState === LOGGEDIN;
      const customerId = state.auth.user?.id;
      const token = state.auth.user?.accessToken;

      if (isLoggedIn && customerId && token) {
        const { wishlist: customerFavorites = [] } = await callNetlifyFunction(
            '/.netlify/functions/fetchWishlist',
            { customerId, token }
        );
        const localFavorites = JSON.parse(localStorage.getItem(FAVORITES_KEY) || '[]');
        const mergedFavorites = [
          ...customerFavorites.filter(
              (item) => !localFavorites.some((localFavorite) => localFavorite.variant.shopifyId === item.variant.shopifyId)
          ),
          ...localFavorites,
        ];
        await callNetlifyFunction(
            '/.netlify/functions/updateWishlist',
            { customerId, wishlist: mergedFavorites, token }
        );
        return mergedFavorites;
      }

      return state.wishlist.favorites;
    }
);

export const WishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getFavorites.fulfilled, (state, action) => {
          state.favorites = action.payload;
        })
        .addCase(addToFavorite.fulfilled, (state, action) => {
          state.favorites = action.payload;
        })
        .addCase(removeFromFavorites.fulfilled, (state, action) => {
          state.favorites = action.payload;
        })
        .addCase(clearFavorites.fulfilled, (state) => {
          state.favorites = [];
        })
        .addCase(syncLocalStorageToMetafield.fulfilled, (state, action) => {
          state.favorites = action.payload;
        });
  },
});

export default WishlistSlice.reducer;
