import mixpanel from 'mixpanel-browser';
import TrackingService from "./TrackingService";
const PRIMARY_URL = process.env.GATSBY_PRIMARY_URL;
const BRANCH = process.env.GATSBY_BRANCH;
export default class MixpanelService extends TrackingService {
  initialize(config) {
    mixpanel.init(config.token, {
      track_pageview: "url-with-path-and-query-string"
    });
    mixpanel.register({
      'branch': BRANCH
    });
    if (process.env.NODE_ENV !== 'production') {
      //mixpanel.disable();
    }
  }

  identify(customerId, properties) {
    mixpanel.identify(customerId);
    mixpanel.people.set({
      '$firstName': properties.firstName,
      '$lastName': properties.firstName,
      '$email': properties.email,
    });
  }

  reset() {
    mixpanel.reset();
  }

  trackEvent(eventName, properties) {
    mixpanel.track(eventName, properties);
  }

  setCookie() {
    const parsedUrl = new URL(PRIMARY_URL);
    let expires = "";
    const days = 30;
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
    const domain = `; domain=.${parsedUrl.hostname}`;
    document.cookie = "bushbuck_mdid=" + mixpanel.get_distinct_id() + expires + domain + "; path=/";
  }

// Example usage:
}
