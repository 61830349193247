class TrackingManager {
  constructor() {
    this.providers = new Map();
  }

  registerProvider(name, ProviderClass, config) {
    const provider = new ProviderClass();
    provider.initialize(config);
    this.providers.set(name, provider);
  }

  identify(customerId, properties) {
    this.providers.forEach(provider => {
      provider.identify(customerId, properties);
    });
  }

  reset() {
    this.providers.forEach(provider => {
      provider.reset();
    });
  }

  trackEvent(eventName, properties) {
    this.providers.forEach(provider => {
      provider.trackEvent(eventName, properties);
    });
  }
}

const trackingManager = new TrackingManager();

export default trackingManager;
