import React, { useCallback, useEffect, useState } from "react";
import { setSessionPassword } from "../../utils/passwordprotect";
import { Link } from "gatsby";
import SvgLogo from "../../assets/icons/SvgLogo";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

const PasswordProtect = () => {
  const [password, setPassword] = useState("");
  const autoplayOptions = {
    delay: 6000,
    rootNode: (emblaRoot) => emblaRoot.parentElement,
  };
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: "start",
      dragFree: false,
      containScroll: "trimSnaps",
      speed: 20,
      loop: true,
    },
    [Autoplay(autoplayOptions)]
  );

  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on("select", onSelect);
    emblaApi.on("reInit", onSelect);
  }, [emblaApi, onSelect]);

  const data = useStaticQuery(graphql`
    query PasswordPageData {
      ContentfulData: contentfulLandingPage {
        heroSectionPartners {
          title
          description {
            description
          }
          buttonText
          buttonLink
          backgroundImage {
            url
            publicUrl
            mimeType
            gatsbyImageData(
              formats: WEBP
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              quality: 100
            )
            title
            file {
              url
            }
          }
          backgroundImageMobile {
            mimeType
            title
            gatsbyImageData(
              formats: WEBP
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              breakpoints: 500
            )
            file {
              url
            }
          }
        }
      }
    }
  `);

  const onSubmit = (event) => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <>
      <div
        className={`group absolute hover:bg-white flex flex-col justify-center z-[9] w-full h-[50px] lg:h-[88px] bg-white hover:text-current border border-solid border-black/10 shadow md:shadow-xl`}
        id="navbar"
        role="group"
      >
        <div className="flex items-center mx-5 lg:mx-10 xl:lg-[90px]">
          <React.Fragment>
            <Link
              to="/"
              title="Bushbuck"
              className={`bg-transparent py-2 cursor-pointer hidden lg:flex text-[#111] group-hover:text-[#111]`}
            >
              <SvgLogo
                color="inherit"
                style={{ width: "140px", height: "36px" }}
              />
            </Link>
          </React.Fragment>
        </div>
      </div>
      <div className="w-full min-h-screen flex flex-col pt-[80px]">
        <div className="relative w-full h-[585px] md:h-full box-border">
          <div
            id="featured-blogs"
            className="w-full relative z-[1] overflow-visible embla"
          >
            <div className="overflow-hidden" ref={emblaRef}>
              <div className="flex flex-row">
                {data.ContentfulData.heroSectionPartners.map(
                  (slider, index) => (
                    <div
                      className="w-screen h-full relative border-inherit z-0 flex-grow-0 flex-shrink-0 basis-full"
                      key={index}
                    >
                      <div
                        className="relative flex flex-wrap lg:flex-initial lg:min-h-[720px] h-full max-h-[585px] md:max-h-full text-white overflow-y-hidden
                  border-inherit after:absolute after:contents-[''] after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-20
                  after:z-[1]"
                      >
                        <div className="absolute top-0 left-0 w-full h-full block overflow-hidden border-inherit">
                          {slider.backgroundImage.mimeType.includes("image") ? (
                            <GatsbyImage
                              image={slider.backgroundImage.gatsbyImageData}
                              alt={slider.backgroundImage.title}
                              className="hidden md:block max-w-full w-full h-full object-cover align-bottom"
                              objectFit="cover"
                              objectPosition="center"
                              loading="eager"
                            />
                          ) : (
                            <video
                              title={slider.backgroundImage.title}
                              src={slider.backgroundImage.file.url}
                              autoPlay
                              loop
                              muted
                              controlsList="nodownload"
                              className="w-full h-full object-cover"
                            >
                              <source
                                src={slider.backgroundImage.file.url}
                                type={slider.backgroundImage.mimeType}
                              />
                            </video>
                          )}
                          {slider.backgroundImageMobile.mimeType.includes(
                            "image"
                          ) ? (
                            <GatsbyImage
                              className="block md:hidden max-w-full w-full h-[585px] md:h-full align-bottom"
                              image={
                                slider.backgroundImageMobile.gatsbyImageData
                              }
                              alt={slider.backgroundImageMobile.title}
                              objectFit="cover"
                              objectPosition="center"
                              loading="eager"
                            />
                          ) : (
                            <video
                              title={slider.backgroundImage.title}
                              src={slider.backgroundImage.file.url}
                              autoPlay
                              loop
                              muted
                              controlsList="nodownload"
                              className="w-full h-full object-cover"
                            >
                              <source
                                src={slider.backgroundImage.file.url}
                                type={slider.backgroundImage.mimeType}
                              />
                            </video>
                          )}
                        </div>
                        <div className="relative w-full flex items-center justify-center lg:justify-start text-left pt-[90px] px-5 pb-5 border-inherit z-[2]">
                          <div className="relative flex flex-col max-w-[1200px] w-full h-[585px] md:h-fit py-20 lg:px-[60px] border-inherit">
                            <h1 className="text-[40px] md:text-[62px] tracking-[0.4px] leading-[60px] md:leading-[93px] font-bold">
                              Welcome Partners
                            </h1>
                            <p className="text-[15px] md:text-base leading-[27px] md:leading-7 tracking-[0.4px] max-w-[500px] mt-[10px]">
                              {slider.description.description}
                            </p>
                            <div className="flex items-center lg:mt-[30px]">
                              <form onSubmit={onSubmit} className="w-[500px]">
                                <div className="flex items-center lg:mt-[30px]">
                                  <div className="relative block w-full mt-10 text-left border-inherit">
                                    <label
                                      className="text-md block pointer-events-none text-white border-inherit"
                                      htmlFor="password"
                                    >
                                      Enter Password to continue
                                    </label>
                                    <input
                                      className="flex-grow text-left appearance-none bg-transparent text-white overflow-hidden pt-[5px] pb-[10px]
        w-full box-border  border-b border-b-white/60 border-solid outline-none"
                                      type="password"
                                      id="password"
                                      value={password}
                                      onChange={(event) =>
                                        setPassword(event.target.value)
                                      }
                                    />
                                  </div>
                                  <button
                                    className="w-fit px-9 rounded-full no-underline text-[#1f1f1f] h-[55px] text-[15px] font-bold tracking-[1px]
                          leading-[27px] box-border mt-[15px] bg-white opacity-100 hover:scale-105 duration-200 ease-linear"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordProtect;
