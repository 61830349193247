class TrackingService {
  initialize(config) {
    throw new Error("Method 'initialize()' must be implemented.");
  }

  trackEvent(eventName, properties) {
    throw new Error("Method 'trackEvent()' must be implemented.");
  }
}

export default TrackingService;
