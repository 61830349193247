import trackingManager from './TrackingManager';

const trackingActions = {};

// Define auth tracking actions
const authTrackingActions = {
  'authSlice/setUser': (payload, state) => ({
    type: 'identify',
    userId: payload.id,
    traits: {
      email: payload.email,
      firstName: payload.firstName,
      lastName: payload.lastName,
    }
  }),
  'authSlice/userLoggedOut': () => ({
    type: 'reset'
  }),
};

const trackingMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  // Check if we have a regular tracking action for this action type
  const trackingAction = trackingActions[action.type];
  if (trackingAction) {
    const { eventName, properties } = trackingAction(action.payload, store.getState());
    trackingManager.trackEvent(eventName, properties);
  }

  // Check if we have an auth tracking action for this action type
  const authTrackingAction = authTrackingActions[action.type];
  if (authTrackingAction) {
    const { type, userId, traits } = authTrackingAction(action.payload, store.getState());
    if (type === 'identify') {
      trackingManager.identify(userId, traits);
    } else if (type === 'reset') {
      trackingManager.reset();
    }
  }

  return result;
};

export default trackingMiddleware;

