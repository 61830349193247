exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-account-index-jsx": () => import("./../../../src/pages/account/index.jsx" /* webpackChunkName: "component---src-pages-account-index-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-blogs-contributors-jsx": () => import("./../../../src/pages/blogs/contributors.jsx" /* webpackChunkName: "component---src-pages-blogs-contributors-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-checkout-jsx": () => import("./../../../src/pages/checkout.jsx" /* webpackChunkName: "component---src-pages-checkout-jsx" */),
  "component---src-pages-collections-jsx": () => import("./../../../src/pages/collections.jsx" /* webpackChunkName: "component---src-pages-collections-jsx" */),
  "component---src-pages-giftcard-balance-jsx": () => import("./../../../src/pages/giftcard-balance.jsx" /* webpackChunkName: "component---src-pages-giftcard-balance-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pages-brands-jsx": () => import("./../../../src/pages/pages/brands.jsx" /* webpackChunkName: "component---src-pages-pages-brands-jsx" */),
  "component---src-pages-pages-bushbuck-club-faqs-jsx": () => import("./../../../src/pages/pages/bushbuck-club-faqs.jsx" /* webpackChunkName: "component---src-pages-pages-bushbuck-club-faqs-jsx" */),
  "component---src-pages-pages-bushbuck-reviews-jsx": () => import("./../../../src/pages/pages/bushbuck-reviews.jsx" /* webpackChunkName: "component---src-pages-pages-bushbuck-reviews-jsx" */),
  "component---src-pages-pages-careers-jsx": () => import("./../../../src/pages/pages/careers.jsx" /* webpackChunkName: "component---src-pages-pages-careers-jsx" */),
  "component---src-pages-pages-community-jsx": () => import("./../../../src/pages/pages/community.jsx" /* webpackChunkName: "component---src-pages-pages-community-jsx" */),
  "component---src-pages-pages-contact-us-jsx": () => import("./../../../src/pages/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-pages-contact-us-jsx" */),
  "component---src-pages-pages-contribute-jsx": () => import("./../../../src/pages/pages/contribute.jsx" /* webpackChunkName: "component---src-pages-pages-contribute-jsx" */),
  "component---src-pages-pages-corporate-sales-jsx": () => import("./../../../src/pages/pages/corporate-sales.jsx" /* webpackChunkName: "component---src-pages-pages-corporate-sales-jsx" */),
  "component---src-pages-pages-distribution-jsx": () => import("./../../../src/pages/pages/distribution.jsx" /* webpackChunkName: "component---src-pages-pages-distribution-jsx" */),
  "component---src-pages-pages-engineering-ethos-jsx": () => import("./../../../src/pages/pages/engineering-ethos.jsx" /* webpackChunkName: "component---src-pages-pages-engineering-ethos-jsx" */),
  "component---src-pages-pages-faqs-jsx": () => import("./../../../src/pages/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-pages-faqs-jsx" */),
  "component---src-pages-pages-founders-story-jsx": () => import("./../../../src/pages/pages/founders-story.jsx" /* webpackChunkName: "component---src-pages-pages-founders-story-jsx" */),
  "component---src-pages-pages-our-story-jsx": () => import("./../../../src/pages/pages/our-story.jsx" /* webpackChunkName: "component---src-pages-pages-our-story-jsx" */),
  "component---src-pages-pages-privacy-policy-jsx": () => import("./../../../src/pages/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-pages-privacy-policy-jsx" */),
  "component---src-pages-pages-prym-1-jsx": () => import("./../../../src/pages/pages/prym1.jsx" /* webpackChunkName: "component---src-pages-pages-prym-1-jsx" */),
  "component---src-pages-pages-returns-exchanges-jsx": () => import("./../../../src/pages/pages/returns-exchanges.jsx" /* webpackChunkName: "component---src-pages-pages-returns-exchanges-jsx" */),
  "component---src-pages-pages-reviews-jsx": () => import("./../../../src/pages/pages/reviews.jsx" /* webpackChunkName: "component---src-pages-pages-reviews-jsx" */),
  "component---src-pages-pages-shipping-jsx": () => import("./../../../src/pages/pages/shipping.jsx" /* webpackChunkName: "component---src-pages-pages-shipping-jsx" */),
  "component---src-pages-pages-sustainability-jsx": () => import("./../../../src/pages/pages/sustainability.jsx" /* webpackChunkName: "component---src-pages-pages-sustainability-jsx" */),
  "component---src-pages-pages-terms-conditions-jsx": () => import("./../../../src/pages/pages/terms-conditions.jsx" /* webpackChunkName: "component---src-pages-pages-terms-conditions-jsx" */),
  "component---src-pages-pages-warranty-information-jsx": () => import("./../../../src/pages/pages/warranty-information.jsx" /* webpackChunkName: "component---src-pages-pages-warranty-information-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-track-jsx": () => import("./../../../src/pages/track.jsx" /* webpackChunkName: "component---src-pages-track-jsx" */),
  "component---src-template-blog-category-jsx": () => import("./../../../src/Template/BlogCategory.jsx" /* webpackChunkName: "component---src-template-blog-category-jsx" */),
  "component---src-template-blog-template-jsx": () => import("./../../../src/Template/BlogTemplate.jsx" /* webpackChunkName: "component---src-template-blog-template-jsx" */),
  "component---src-template-bundle-collection-jsx": () => import("./../../../src/Template/BundleCollection.jsx" /* webpackChunkName: "component---src-template-bundle-collection-jsx" */),
  "component---src-template-bushbuck-club-jsx": () => import("./../../../src/Template/BushbuckClub.jsx" /* webpackChunkName: "component---src-template-bushbuck-club-jsx" */),
  "component---src-template-competitions-jsx": () => import("./../../../src/Template/Competitions.jsx" /* webpackChunkName: "component---src-template-competitions-jsx" */),
  "component---src-template-contributor-jsx": () => import("./../../../src/Template/Contributor.jsx" /* webpackChunkName: "component---src-template-contributor-jsx" */),
  "component---src-template-product-collection-jsx": () => import("./../../../src/Template/ProductCollection.jsx" /* webpackChunkName: "component---src-template-product-collection-jsx" */),
  "component---src-template-product-jsx": () => import("./../../../src/Template/Product.jsx" /* webpackChunkName: "component---src-template-product-jsx" */),
  "slice---src-components-announcement-bar-announcements-jsx": () => import("./../../../src/components/AnnouncementBar/Announcements.jsx" /* webpackChunkName: "slice---src-components-announcement-bar-announcements-jsx" */),
  "slice---src-components-footer-footer-jsx": () => import("./../../../src/components/Footer/Footer.jsx" /* webpackChunkName: "slice---src-components-footer-footer-jsx" */),
  "slice---src-components-home-categories-index-jsx": () => import("./../../../src/components/home/Categories/index.jsx" /* webpackChunkName: "slice---src-components-home-categories-index-jsx" */),
  "slice---src-components-home-hero-jsx": () => import("./../../../src/components/home/Hero.jsx" /* webpackChunkName: "slice---src-components-home-hero-jsx" */),
  "slice---src-components-home-join-club-jsx": () => import("./../../../src/components/home/JoinClub.jsx" /* webpackChunkName: "slice---src-components-home-join-club-jsx" */),
  "slice---src-components-home-products-section-index-jsx": () => import("./../../../src/components/home/ProductsSection/index.jsx" /* webpackChunkName: "slice---src-components-home-products-section-index-jsx" */),
  "slice---src-components-home-shop-section-index-jsx": () => import("./../../../src/components/home/ShopSection/index.jsx" /* webpackChunkName: "slice---src-components-home-shop-section-index-jsx" */),
  "slice---src-components-navbar-index-jsx": () => import("./../../../src/components/Navbar/index.jsx" /* webpackChunkName: "slice---src-components-navbar-index-jsx" */)
}

